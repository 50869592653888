import { FunctionComponent } from "react"
import { Helmet as Head } from "react-helmet"
import React from "react"
import { useChannel } from "../hooks/use-channel"
import TagFilters from "../components/layout/tag-filters"
import Bookmarks from "../components/layout/bookmarks"
import SortFilter from "../components/layout/sort-filter"
import Feeds from "../components/layout/feeds"
import ChannelView from "../components/channel/view"
import MinimalSortFilter from "../components/layout/minimal-sort-filter"
import FeedbackFilter from "../components/layout/feedback-filter"
import ResponsiveLayout from "../components/layout/responsive-layout"

const HomePage: FunctionComponent = () => {
  const { channel, donationsEnabled } = useChannel("", "amount")

  return (
    <div>
      <Head>
        <title>Explore Smartlike</title>
        <meta name="description" content="Smartlike p2p donations" />
        <meta property="og:title" content="Explore Smartlike" />
        <meta property="og:image" content="/favicons/smartlike_512.png" />
        <meta property="og:description" content="Smartlike p2p donations" />
      </Head>
      <ResponsiveLayout channel={channel}>
        {{
          content: <Feeds initialChannelIds={[]} />,
          sidebar: (
            <>
              <SortFilter />
              <MinimalSortFilter />
              <FeedbackFilter />
              <ChannelView
                channel={channel}
                donationsEnabled={donationsEnabled}
              />
              <TagFilters />
              <Bookmarks />
            </>
          ),
        }}
      </ResponsiveLayout>
    </div>
  )
}
export default HomePage
